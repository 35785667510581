html, body {
    margin: 0;
    padding: 0;
    font-family: avenir;
    height: 100%;
}

#react-content, .page-container { min-height: 100vh; }

.page-container {
    transition: background-color 0.5s, color 0.5s;
    color: #4a4b4c;
}

.page-container.dark {
    background: #191C1E;
    color: #efefef;
}

/* DARK MODE STUFFF please appreciate this it was hard, react is hard :( )*/

.page-container.dark .nav-link a { color: #FFF }
.page-container.dark .nav-link a:hover { color: #E15554 }
.page-container.dark .card:hover { background-color: #292E31 }
.page-container.dark .dark-mode-switch { background-color: #E25554 }
.page-container.dark .dark-mode-switch-wrapper .label {color: #fff }
.page-container.dark .dark-mode-switch-wrapper { background-color: #191D1F }
.page-container.dark .dark-mode-switch-wrapper { box-shadow: none }
.page-container.dark .card-tag {
    background-color:#2D63BC;
    color: #fff;
}
.page-container.dark .tag {
    background-color:#2D63BC;
    color: #fff;
}
.page-container.dark .tag-divider {
    background-color:#2D63BC;
    color: #fff;
}
.page-container.dark .tag-divider-1 {
    background-color:#2D63BC;
    color: #fff;
}
.h3{
    font-size: 24px;
    font-weight: 500;

}


/* Navigation bar styles */

.nav-bar {
    width: 100%;
    height: 90px;
}
.search-logo { float: left; }
.search-logo img {
    width: 50px;
    padding-left: 80px;
    padding-top: 20px;
}
.nav-contents {
    float: right;
    padding-top: 35px;
    padding-right: 50px;
    font-weight: 500;
    letter-spacing: 0.02em;
}

.nav-link {
    float: left;
    padding-right: 35px;
    opacity: .5;
}
.nav-link.active {
    opacity: 1;
}
.nav-link a {
    color: #282A37;
    text-decoration: none;
}
.nav-link a:hover {
    color: #E15554;
    text-decoration: none;
}

/* ABOUT */
.about-page{
    margin-top: 10px;
    width: 900px;
    margin: 0 auto;
    display: flex;
}

.about-content {
    margin-top: 60px;
    margin-bottom: 60px; }

.aboutpic img {
    width: 350px;
    border-radius: 3%;
    margin-top: 60px;
}

.page-header{
    font-size: 42px;
    font-weight: 600;
    display: inline-block;
}

.side-info{
    display: inline-block;
    padding-top: 10px;

}
.side-info a{
    color: #E15554;
    text-decoration: none;
}
.about-bio{
    display: inline-block;
    line-height: 29px;
}

@media (min-width: 960px) {
    .about-content { margin-left: 50px; }}

@media (max-width: 959px) {
    .about-page {
        width: 450px;
        flex-wrap: wrap;
        justify-content: center;
    }

.aboutpic img { width: 450px; }}

/* BIO */
.bio {
    margin: 100px auto;
    text-align: center;
    font-size: 42px;
    width: 960px;
    
}

.portfolio-wrapper {
    margin: 0 auto;
    padding-bottom: 60px;
    width: 960px;
    overflow: hidden;
}
.card {
    border-radius: 12px;
    width: 440px;
    float: left;
    margin-right: 40px;
    margin-bottom: 25px;
    padding: 10px;
    transition: background-color 0.7s;
    cursor: pointer;
    overflow: hidden;

}
.card:nth-child(2n) {
    margin-right: 0;
}
.card:hover {
    background: #f2f3f4;
}
.card img {
    border-radius: 10px;
    width: 440px;
}

.card .card-tag {
    float: right;
    border-radius: 10px;
    padding: 6px 18px;
    margin-left: 14px;
    margin-bottom: 5px;

    font-size: 14.5px;
    font-weight: 200;
    letter-spacing: -0.2px;
    -webkit-font-smoothing: antialiased;
    background-color: #D2F1FF;
    color: #0076FF;

}

.card-title {
    display: inline-block;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: -0.3px;
    line-height: 32px;
    margin: 0;
}
.card-text {
    padding-top: 10px;
    padding-left:20px;
    padding-right:20px;
}

/* Dark mode switch */
.dark-mode-switch-wrapper {
    position: fixed;
    bottom: 30px;
    right: 30px;
    padding: 15px 20px;
    border-radius: 80px;
    background: #FFF;
    box-shadow: 1px 1px 6px 2px rgba(0,0,0,0.06);
}

.dark-mode-switch-wrapper .label {
    display: inline-block;
    vertical-align: top;
    margin-right: 15px;
    line-height: 22px;
    font-size: 13px;
    color: black;
}

.dark-mode-switch {
    display: inline-block;
    vertical-align: top;
    width: 40px;
    height: 20px;
    border-radius: 15px;
    background: #3B93FF;
    cursor: pointer;
}

.dark-mode-switch .toggle {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    background: white;
    box-shadow: 2px 2px 4px 2px rgba(0,0,0,0.12);
    transition: all 0.3s;
}

.dark-mode-switch .toggle.right { transform: translateX(20px); }



.project-header {
    text-align: center;
    padding: 0 20px;
    box-sizing: border-box;
}

.project-title {
    margin-top: 60px;
    font-size: 42px;
    font-weight: 600;
}
.project-text {
    font-size: 16px;
    margin-top: 28px;
    margin-bottom: 20px;
    text-align: center;
    line-height: 2em;
}
.project-text a{
    color: #282A37;
    text-decoration: none;
}
.project-text a:hover{
    color: #E25554;
}

.tag {
    display: inline-block;
    border-radius: 10px;
    background-color: #D2F1FF;
    color: #0076FF;
    width: 70px;
    text-align: center;
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 15px;
    padding: 6px 18px;
}

.tag-explainer {
    font-size: 24px;
    font-weight: 500;
    text-align: center;
    margin: 0 auto;
    margin-top: 25px;
    margin-bottom: 35px;
}

.project-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}
.project-profile-pic { text-align: center; }
.project-profile-pic img {
    width: 640px;
    border-radius: 10px;
}

.tag-divider{
    border-radius: 10px;
    background-color: #D2F1FF;
    color: #0076FF;
    width: 120px;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 15px;
    padding: 6px 18px;
}
.tag-divider-1{
    border-radius: 10px;
    background-color: #D2F1FF;
    color: #0076FF;
    width: 190px;
    text-align: center;
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 15px;
    padding: 6px 18px;
}

.project-description{
    text-align: left;
    margin-bottom: 28px;
    line-height: 1.8em;
    /* letter-spacing: 0.01em; */
}
.project-description a{
    color: #E25554;
    text-decoration: none;
}

.project-content img {
    display: block;
    margin: 0 auto;
    width: 75%;
}

@media screen and (max-width: 960px) {
    .portfolio-wrapper { width: 460px }
    .bio { width: 450px;}
    .card { margin-right: 0 }
}

@media screen and (max-width: 376px) {
    .bio{
        width: 350px;
        margin-top: 100px;
        margin-left: 45px;
    }
    .project-content {
        width: 350px;
    }
    .portfolio-wrapper { width: 300px }
    .aboutpic img { width: 300px; }
    .about-page{margin: 0 auto; width: 300px;}
    .about-content .page-header{
        text-align: center;
        display: block;
    }
    .side-info{
        padding-left: 10px;
        padding-right: 10px;
        width: 300px;
    }
    .about-bio{
        padding-bottom: 30px;
        text-align: justify;
    }
    .card { margin-right: 0; }
}

@media screen and (max-width: 960px) {
    .portfolio-wrapper { width: 460px }
    .card { margin-right: 0 }
}